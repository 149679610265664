@tailwind base;
@tailwind components;
@tailwind utilities;

.file {
    white-space: pre;
    padding: 5px;
    color: white;
    background-color: black;
    font-family: 'Consolas';
    overflow: scroll;
}

div.copy-file-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
}

td.wrap, .parameter-value, .offcanvas-header, .wrap {
    word-break: break-word !important; /* Chrome, Safari */
    word-wrap: break-word !important; /* IE11, Firefox */
}

td.nowrap {
  word-break: initial !important;
  word-wrap: initial !important;
}

.spin{
  animation: 1s linear 0s infinite spin;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}